const Wrapper = ({ children }: any) => (
    <>
        
         <div className="top">
              
                </div>
        <div className="bigcontainer">
            <div className="container wbg">
               
                {children}
            </div>
        </div>
        <div className="FootB"><img src={'footerx.png'} className="Fotbanner" alt="header" />  
        </div>
    </>

)

export default Wrapper;
