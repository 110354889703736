import { useEffect, useState } from "react";
import config from "../config";
import Gift from "../entities/Gift";
import GiftView from "./GiftView";
import { useAuth } from "../context/Auth";

export default function GiftsList({ eventId, data }: any) {
  const [gifts, setGifts] = useState<Gift[]>([]);
  const [selected, setSelected] = useState<number>(data.holidayGiftID);
  const { fetchWithUser } = useAuth()

  useEffect(() => {
    const url = `${config.apiUrl}/HolidayGift/GetHolidayGiftList/${eventId}`;
    fetchWithUser(url)
      .then((response) => response.json())
      .then((gifts) => setGifts(gifts.filter((g: Gift) => !g.isBackOfficeOnly)));
  }, [eventId, fetchWithUser])

  return (
    <ol>
      {gifts.map((gift) => (
        <li className="GiftItem">
          <GiftView gift={gift} selected={selected} onChange={setSelected} />
        </li>
      ))}
    </ol>);
}